.description {
  margin: 0 5%;
}
.description p {
  font-family:'Nunito','sans-serif';
}
.description h2 {
  font-family: 'Nunito','sans-serif';
  font-size: 2.25rem;
}
.description .descriptionTop {
  margin: 0 auto;
  padding: 2.5rem var(--content-side-padding-value) 0;
}
.description .descriptionTop .descriptionHeading {
  margin-bottom: 1rem;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
}
.description .descriptionTop .descriptionOpeningParagraph {
  margin: 0 auto;
  font-size: 1.2rem;
  text-align: center;
}
.description .descriptionTop .descriptionImage {
  display: block;
  margin: 2rem auto;
  height: auto;
  width: 100%;
  background-size: cover;
  border-radius: 10px;
}
.description .descriptionTop .descriptionMission {
  margin: 4rem auto;
  text-align: center;
}
.description .descriptionTop .descriptionMission .descriptionMissionHeading {
  font-family:  'Nunito','sans-serif';
  font-weight: bold;
  font-size: 2.275rem;
}
.description .descriptionTop .descriptionMission .missionContent {
  font-size: 1.3rem;
  font-weight: bold;
}
.description .descriptionMain {
  padding: 0 8vw;
}
.description .descriptionMain p {
  margin: 1rem auto;
  font-size: 1.2rem;
  text-align: justify;
}
@media screen and (orientation: landscape) {
  .description h2 {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 23.125rem) {
  .description h2 {
    font-size: 2.5rem;
  }
  .description .descriptionTop .descriptionMission .descriptionMissionHeading {
    font-size: 2.625rem;
  }
  .description .descriptionTop .descriptionMission .missionContent {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 23.125rem) and (orientation: landscape) {
  .description .descriptionTop .descriptionMission .descriptionMissionHeading {
    font-size: 2.3625rem;
  }
  .description .descriptionTop .descriptionMission .missionContent {
    font-size: 1.35rem;
  }
}
@media screen and (min-width: 43.75rem) {
  .description .descriptionTop {
    padding: 4rem 6.25vw 0;
  }
  @supports (padding: max(0px)) {
    @media screen and (orientation: landscape) {
      .description .descriptionTop {
        padding-left: max(6.25vw, calc(env(safe-area-inset-left)));
        padding-right: max(6.25vw, calc(env(safe-area-inset-right)));
      }
    }
  }
  .description .descriptionTop .descriptionHeading {
    font-weight: 400;
  }
  .description .descriptionTop .descriptionImage {
    border-radius: 15px;
  }
  .description .descriptionMain {
    padding: 0 10vw;
  }
  @supports (padding: max(0px)) {
    @media screen and (orientation: landscape) {
      .description .descriptionMain {
        padding-left: max(10vw, calc(env(safe-area-inset-left) + 10vw));
        padding-right: max(10vw, calc(env(safe-area-inset-right) + 10vw));
      }
    }
  }
}
@media screen and (min-width: 43.75rem) and (orientation: landscape) {
  .description .descriptionTop .descriptionImageContainer {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
  .description .descriptionTop .descriptionMission .descriptionMissionHeading {
    font-size: 2.5375rem;
  }
  .description .descriptionTop .descriptionMission .missionContent {
    font-size: 1.45rem;
  }
}
@media screen and (min-width: 48rem) {
  .description .descriptionTop .descriptionMission .descriptionMissionHeading {
    font-size: 2.8rem;
  }
  .description .descriptionTop .descriptionMission .missionContent {
    font-size: 1.6rem;
  }
}
@media screen and (min-width: 64rem) {
  .description .descriptionTop {
    padding: 4rem 0 0;
  }
  .description .descriptionTop .descriptionImage {
    max-width: 52rem;
  }
  .description .descriptionTop .descriptionMission .descriptionMissionHeading {
    font-size: 2.975rem;
  }
  .description .descriptionTop .descriptionMission .missionContent {
    font-size: 1.7rem;
  }
  .description .descriptionMain p {
    padding: 0;
    max-width: 42rem;
  }
}
@media screen and (min-width: 85.375rem) {
  .description {
    max-width: var(--large-screen-max-width);
  }
}
