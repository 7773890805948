@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
.App {
  text-align: center;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
}
/* body {
  background-color: #52acff;

} */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #52acff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center!important;
  justify-content: center!important;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-header > form > .row {
  justify-content: center;
}
.App-header > form > .row > span {
  font-family: "Open Sans";
  font-size: 27px;
}

.App > .row {
  justify-content: space-around;
}
.col1 {
  padding: 0px;
  margin: 8px;
  border-radius: 15px;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.15);
  max-width: 37% !important;
}
.col1:hover {
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.15);
}
.col1 > a {
  margin-left: 0;
  margin-top: 0;
  text-decoration: none !important;
  font-size: 33px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.col1 > a > h2 {
  font-size: 2rem;
  margin-top: -7%;
  margin-bottom: 3%;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
}
.App-link {
  color: #61dafb;
}
.upload-btn {
  margin-bottom: 40px !important;
}
/* .App-header button {
   background-color: #52acff;
  font-weight: 500;
  
  width: 100px; 
  margin-bottom: 15px;
} */
.btn2 {
  margin: 50px auto;
}
.btn1 {
  margin: 0 10px;
  width: 100px;
}
.btn1 > a {
  margin-left: 0 !important;
  text-decoration: none !important;
}
.btn1:hover {
  font-weight: 600 !important;
}
.img1 {
  width: 100%;
}
#register input[type="text"],
#register textarea,
#register select {
  background-color: #f0f0f0;
}

.App-header > form > .row > .btn {
  margin: 0;
  height: 38px;
}
.App-header > h2,
.App-header > h4 {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.google-sign > h6 {
  font-size: 15px;
}
.makeStyles-paper-1 {
  border: none;
  border-radius: 18px;
}
@media only screen and (max-width: 600px) {
  .col1 {
    max-width: auto;
    max-width: 70% !important;
    margin-left: 0%;
    margin-bottom: 3%;
  }
  .App > .row {
    justify-content: center;
  }
  .App {
    margin-top: 22px;
    margin-bottom: 0;
  }
  .App-header > form > .row > span {
    margin-right: 0px !important;
    width: auto;
    text-align: right;
  }
  .App-header > form > div > input {
    width: 215px !important;
  }
  .App-header > h2 {
    font-size: 29px;
  }
  .App-logo {
    height: 47vmin;
  }
  .App-header > form > div > .bluearrow {
    margin-top: 20px !important;
  }
}
