.top {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../../assets/pages/about/about-header-desktop.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 5.28rem!important;
}
.top .topContainer {
  padding: 10% 6.25vw;
  display: flex;
  flex-direction: column;
}
.top h1 {
  margin: 0 auto 12.5%;
  color: #fff;
  font-family: 'Nunito', sans-serif;
  font-size: 2.25rem;
  font-weight: 400;
  text-align: center;
}
@media screen and (max-width: 22rem) {
  .top h1 {
    font-size: 1.75rem;
  }
}
.top .joinButton {
  margin: 12.5% auto 0;
  color: #fff;
  font-family: 'Nunito', sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  transition: 0.2s;
  transition-timing-function: ease-in-out;
  padding: 1rem;
  background-color: #4d5eff;
  border-radius: 50px;
  width: 95%;
}
.top .joinButton:hover {
  background-color: #5767fe;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}
.top .joinButton:active {
  background-color: #6574f7;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
@media screen and (orientation: landscape) {
  .top {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../../assets/pages/about/about-header-desktop.jpg');
  }
  .top .topContainer {
    padding: 5vw 6.25vw;
  }
  .top h1 {
    margin: 0 auto 5%;
    font-size: 2.4rem;
  }
  .top .joinButton {
    margin: 5% auto 0;
    font-size: 1.25rem;
    width: auto;
    padding: 1rem 2.5rem;
  }
}
@media screen and (min-width: 43.75rem) {
  .top {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../../assets/pages/about/about-header-desktop.jpg');
  }
  .top h1 {
    margin-bottom: 7.5%;
    font-size: 2.5rem;
  }
  .top .joinButton {
    margin: 7.5% auto 0;
    font-size: 1.75rem;
    width: auto;
    padding: 1rem 2.5rem;
  }
}
@media screen and (min-width: 43.75rem) and (orientation: landscape) {
  .top {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../../assets/pages/about/about-header-desktop.jpg');
    padding: 0;
  }
  .top .topContainer {
    padding: 5vw 6.25vw;
  }
  .top h1 {
    margin: 0 auto 5%;
    font-size: 2.75rem;
  }
  .top .joinButton {
    margin: 5% auto 0;
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 64rem) {
  .top h1 {
    margin-bottom: 5%;
    font-size: 3.5rem;
  }
  .top .joinButton {
    margin-top: 5%;
    font-size: 1.75rem;
  }
}
@media screen and (min-width: 68.75rem) {
  .top h1 {
    font-size: 4rem;
    margin-bottom: 5%;
  }
  .top .joinButton {
    margin-top: 5%;
    font-size: 1.75rem;
  }
}
