.cardview{
    width: 280px;
    height: auto;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    /*border-radius: 15px;
    transition: .2s;
    box-shadow: 2px 1px 18px -11px rgba(0,0,0,0.75);
    -webkit-box-shadow: 2px 1px 18px -11px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 1px 18px -11px rgba(0,0,0,0.75);*/

    /* Added Some Border Radius & Transitions */
    transition: box-shadow 0.7s ease;
    border-radius: 12px;
}


.cardview:hover{
    box-shadow: 2px 1px 18px -8px rgba(0,0,0,0.75);
    -webkit-box-shadow: 2px 1px 18px -8px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 1px 18px -8px rgba(0,0,0,0.75);
}

.cardview  .profile{
    width: 175px;
    height: 175px;
    border-radius: 50%;
    margin-bottom: 30px;
    background-position: center;
    background-size: cover;
}

.cardview h4{
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
}


.role{
    font-family: 'Raleway', sans-serif !important;
    font-weight: normal !important;
}

.linkedin{
    color:rgb(0, 119, 181);
    transition: all 0.5s ease;
}

.linkedin:hover{
    transform: scale(1.1);
    transition: transform 0.5s ease;
}

.cardview small{
    text-align: center;
}

.sub-role{
    font-weight: 900;
    color: blue;
}

.socialprofile{
    margin: 5px;
}

.twitter{
    color:rgb(29, 161, 242);
}

.twitter:hover{
    transform: scale(1.1);
    transition: all 0.5s ease;
}

.cardview p {
    margin-top: 10px;
}

@media screen and (max-width:750px){
    .cardview{
        max-width: 350px;
        width: 80%;
    }

}