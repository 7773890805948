.dashboard{
    margin: 15vh 10vw;
}
.dashbaordHeader{
    font-size: 2.25rem;
    margin-top: 120px;
    font-weight: bold;
    font-family: 'Nunito','sans-serif';
    text-align: center;
    margin-bottom: 1rem;
}
.profileborder{
    width: 125px;
    height: 125px;
    border-radius: 50%;
    border:8px solid #4285F4;
    overflow: hidden;
}
.profilePhoto{
    width: 100%;
    height: 100%;
}
.dashboard h1{
    font-weight: bold;
}
.announcements{
    border-radius: 10px;
    box-shadow: -1px -1px 5px 2px rgba(0,0,0,.3);
    padding: 20px;
    margin-top: 40px;
}
.announcementsdiv{
    display: flex;
    flex-direction: column;
    height: auto;
    overflow: hidden;
}
.showmore{
    height: auto;
    overflow: visible !important;
}
.announcements>text{
    border-bottom:1px solid lightgray;
    margin: 5px 0;
}
.announcements>text:hover{
    cursor: pointer;
}
.event_headline{
    font-size: 1.5rem !important;
    color: #007bff;   
    font-weight: bold;
}
.event_description{
    font-size: 1.1rem !important;
}
.event_headline:hover{
    text-decoration: underline;  
    color: #2368b1;  
}
.hr{
    margin: 10px 0;
    border:0;
    border-top: 2px dotted rgba(0,0,0,.3) !important;
}
.announcementsdiv > text > strong{
    font-size: 1.1rem;
}
.announcementsdiv > text > p{
    font-size: 1.3rem;
}
.up_next{
    border-radius: 10px;
    box-shadow: -1px -1px 5px 2px rgba(0,0,0,.3);
    width: 400px;
    margin-top: 40px;
    padding-top: 25px;
    padding-bottom: 20px;
    height: 400px;
}
.up_next div{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.see_more{
    font-size: 1.3rem;
    cursor: pointer;
    color: #007bff;
}
.up_next .see_more{
    padding-left: 20px;
}

@media screen and (max-width:64rem) {
    .up_next{
        width: 80vw;
    }
     .headline{
        width: 100%;
        text-align: center;
        font-size: 1.5rem;
        margin-bottom: 5px;
        font-weight: 600;
    }
}
@media screen and (min-width:64rem) {
    .dashboard_content{
        display: flex;
        justify-content: space-evenly;
    }
    .announcements{
        flex:.9;
        margin-right: 30px;
    }
    .up_next{
        max-width:500px;
    }
     .headline{
        text-align: center;
        font-size: 2rem;
        margin-bottom: 5px;
        font-weight: 650;
    }
}
