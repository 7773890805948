.events{
    margin-top: 7rem!important;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.events h1{
    font-weight: bold;
}
.events > span{
    font-size: 2rem;
}
.events > p{
    text-align: center;
    font-size: 1.3rem;
}
.events .eventcards{
    margin: auto 30px;
} 

@media screen and (min-width:700px){
    .wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
    
    /* If only one Event is upcoming, bring it to the center */
    .wrapper:has(a:only-child){
        display: block;
    }
}
@media screen and (max-width:700px){
    .events .eventcards{
        display: flex;
        flex-direction: column;
    }
}