@import url(https://fonts.googleapis.com/css?family=Roboto:900);
body,
html {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
/* dark-bg{
  background-color: #282c34;
   background-color: 
} */

/* body { */
  /* background: #aecad8; */
  /* background-color: #282c34; */
  /* background: #fff; */
  /* transition: all 0.14s ease-in; */
/* } */

.container {
  margin: auto;
  width: 212px;
}

/* .power {
  margin: auto;
  margin-top: 20px;
  background: #38434A;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-family: Roboto;
  font-size: 1.9em;
  height: 65px;
  letter-spacing: 0.2px;
  line-height: 65px;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 150px;
  transition: none;
} */
/* .power:hover {
  background: #414e56;
} */
/* .power:active {
  box-shadow: inset 0px 4px 10px 0px #2d363b;
  transition: background 0.14s ease-in;
} */
/* .active .power {
  background: #58C48D;
} */
/* .active .power:active {
  box-shadow: inset 0px 6px 8px -1px #41b77b;
} */

#on {
  transform: translate(50%, 50%) scale(0);
  opacity: 0;
}
.active #on {
  opacity: 1;
  transform: translate(0) scale(1);
  transition: all 0.14s ease-in;
}

.fancy-bulb {
  position: relative;
  margin: auto;
  top: 25%;
  margin-top: 200px;
}

.streaks,
.streaks:after,
.streaks:before {
  position: absolute;
  background: #ffdb55;
  border-radius: 5.5px;
  height: 11px;
}

.streaks:after,
.streaks:before {
  content: "";
  display: block;
}

.streaks:before {
  bottom: 65px;
}

.streaks:after {
  top: 65px;
}

.left-streaks {
  right: 217px;
  top: 89.5px;
}
.active .left-streaks {
  -webkit-animation: move-left 0.38s ease-out, width-to-zero 0.38s ease-out;
  animation: move-left 0.38s ease-out, width-to-zero 0.38s ease-out;
  -webkit-animation-delay: 0.14s;
  animation-delay: 0.14s;
}
.left-streaks:before,
.left-streaks:after {
  left: 15px;
}
.active .left-streaks:before {
  -webkit-animation: width-to-zero 0.38s ease-out, move-up 0.38s ease-out;
  animation: width-to-zero 0.38s ease-out, move-up 0.38s ease-out;
  -webkit-animation-delay: 0.14s;
  animation-delay: 0.14s;
}
.active .left-streaks:after {
  -webkit-animation: width-to-zero 0.38s ease-out, move-down 0.38s ease-out;
  animation: width-to-zero 0.38s ease-out, move-down 0.38s ease-out;
  -webkit-animation-delay: 0.14s;
  animation-delay: 0.14s;
}

.right-streaks {
  left: 217px;
  top: 89.5px;
}
.active .right-streaks {
  -webkit-animation: move-right 0.38s ease-out, width-to-zero 0.38s ease-out;
  animation: move-right 0.38s ease-out, width-to-zero 0.38s ease-out;
  -webkit-animation-delay: 0.14s;
  animation-delay: 0.14s;
}
.right-streaks:before,
.right-streaks:after {
  right: 15px;
}
.active .right-streaks:before {
  -webkit-animation: width-to-zero 0.38s ease-out, move-up 0.38s ease-out;
  animation: width-to-zero 0.38s ease-out, move-up 0.38s ease-out;
  -webkit-animation-delay: 0.14s;
  animation-delay: 0.14s;
}
.active .right-streaks:after {
  -webkit-animation: width-to-zero 0.38s ease-out, move-down 0.38s ease-out;
  animation: width-to-zero 0.38s ease-out, move-down 0.38s ease-out;
  -webkit-animation-delay: 0.14s;
  animation-delay: 0.14s;
}

.left-streaks:before,
.right-streaks:after {
  transform: rotate(34deg);
}

.left-streaks:after,
.right-streaks:before {
  transform: rotate(-34deg);
}

@-webkit-keyframes move-left {
  0% {
    transform: none;
  }
  65% {
    transform: translateX(-80px);
  }
  100% {
    transform: translateX(-80px);
  }
}

@keyframes move-left {
  0% {
    transform: none;
  }
  65% {
    transform: translateX(-80px);
  }
  100% {
    transform: translateX(-80px);
  }
}
@-webkit-keyframes move-right {
  0% {
    transform: none;
  }
  65% {
    transform: translateX(80px);
  }
  100% {
    transform: translateX(80px);
  }
}
@keyframes move-right {
  0% {
    transform: none;
  }
  65% {
    transform: translateX(80px);
  }
  100% {
    transform: translateX(80px);
  }
}
@-webkit-keyframes width-to-zero {
  0% {
    width: 50px;
  }
  100% {
    width: 11px;
  }
}
@keyframes width-to-zero {
  0% {
    width: 50px;
  }
  100% {
    width: 11px;
  }
}
@-webkit-keyframes move-up {
  100% {
    bottom: 100.75px;
  }
}
@keyframes move-up {
  100% {
    bottom: 100.75px;
  }
}
@-webkit-keyframes move-down {
  100% {
    top: 100.75px;
  }
}
@keyframes move-down {
  100% {
    top: 100.75px;
  }
}
