.resources{
  margin-top: 5.26rem!important;
}
.resources p {
  margin-bottom: 1rem;
}
.resources h2 {
  text-align: left;
  font-size: 2rem;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 0;
}
.resources h3, .resources h4 {
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
}
.resources h3 {
  margin-top: 1.75rem;
  margin-bottom: 1rem;
}
.resources h4 {
  margin-top: 1rem;
  font-size: 1.25rem;
}
.resources li {
  margin-top: 0.5rem;
}
.resources .contribute {
  margin-top: 2rem;
  padding: 1.5rem;
}
.resources .contribute .alertHeading {
  font-weight: bold;
  font-size: 1.75rem;
}
.resources .contribute p {
  margin-bottom: 0;
}

.container, .expandedWidthContainer {
    margin: 0 auto;
    padding: 4vw;
    width: 100vw !important;
    font-size: 1.25rem;
    font-family: 'Nunito', sans-serif;
    min-height: 100vh;
  }
  @media screen and (min-width: 43.75rem) {
    .container, .expandedWidthContainer {
      margin: 0 auto 20%;
      padding: 3rem 8vw;
    }
    @supports (padding: max(0px)) {
      @media screen and (orientation: landscape) {
        .container, .expandedWidthContainer {
          padding-left: max(8vw, calc(env(safe-area-inset-left) + 8vw));
          padding-right: max(8vw, calc(env(safe-area-inset-right) + 8vw));
        }
      }
    }
  }
  @media screen and (min-width: 64rem) {
    .container, .expandedWidthContainer {
      margin: 0 auto 20%;
      padding: 3rem 0;
      max-width: 53.8125rem;
    }
  }
  @media screen and (min-width: 1920px) {
    .container, .expandedWidthContainer {
      margin: 0 auto 12.5%;
    }
  }
  @media screen and (min-width: 2560px) {
    .container, .expandedWidthContainer {
      margin: 0 auto 25%;
    }
  }
  @media screen and (min-width: 3840px) {
    .container, .expandedWidthContainer {
      margin: 0 auto 35%;
    }
  }
  @media screen and (min-width: 64rem) {
    .expandedWidthContainer {
      max-width: initial;
      margin: auto 5vw;
    }
  }
  @media screen and (min-width: 1280px) {
    .expandedWidthContainer {
      max-width: 1200px;
      margin: 0 auto;
    }
  }
  .text h1 {
    margin: auto 8vw;
    font-size: 2.5rem;
    font-weight: bold;
    font-family: 'Raleway', sans-serif;
  }
  .text h2 {
    font-family: 'Raleway', sans-serif;
    font-size: 2rem;
    margin-bottom: 2.5vh;
  }
  .text p {
    font-family: 'Nunito', sans-serif;
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
  @media screen and (min-width: 43.75rem) {
    .text h1 {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
    .text h2 {
      font-size: 2.5rem;
      margin: 0 auto 2.5rem;
    }
    .text p {
      margin-bottom: 2.5rem;
    }
  }
  .centerElement {
    margin: 0 auto;
    text-align: center;
  }
  .emoji {
    margin: 0 auto;
    display: block;
    text-align: center;
    font-size: 5rem;
  } 
  @media screen and (min-width: 43.75rem) {
    .actionButton {
      margin: 0 auto;
      width: 35%;
    }
  }
  .marginTopOneRem {
    margin-top: 1rem;
  }
  .marginTopOnePointFiveRem {
    margin-top: 1.5rem;
  }
  .marginTopTwoRem {
    margin-top: 2rem;
  }
  