.member .volunteer .contributeRoleToggle {
  display: block;
  margin: 2rem auto 1.2rem;
  color: #fff;
  font-family: "Nunito", "sans-serif";
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  transition: 0.2s;
  transition-timing-function: ease-in-out;
  padding: 1rem;
  background-color: #4d5eff;
  border-radius: 50px;
  width: 95%;
}

.volunteer{
  margin-bottom: 4rem;
}
.member .volunteer .contributeRoleToggle:hover {
  background-color: #5767fe;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); */
}
.member .volunteer .contributeRoleToggle h2 {
  margin: 0;
}
.member .volunteer .contributeRole {
  margin-top: 1.5rem;
}
.member .volunteer .contributeRole h2 {
  font-size: 1.75rem;
  font-weight: bold;
  width: auto;
}
