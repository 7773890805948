.eventdetail{
    margin-top: 5.3125rem!important;
}
.eventdetail_details h2,.eventdetail_details h3 {
    font-family: 'Nunito','sans-serif';
    font-weight: bold;
}
.eventdetail_details > h2{
    max-width: 400px;
}
.Description{
    width: 100%;
    font-size: 1.3rem;
    padding : 0 10% 60px 10%;
}
.Description > p{
    text-align: justify;
}
.eventdetail_card{
    overflow: hidden;
    box-shadow: 2px 3px 10px rgba(0,0,0,0.5);
}
.eventdetail_card > img{
    width:100%;
    height: 100%;
    background-position: center;
    background-size: cover;
}
.eventdetail_details_info{
    display: flex;
    align-items: center;
}
.eventdetail_details_info{
    margin-left: 20px;
}
.eventdetail_details_info_text {
    margin-left: 20px;
}
.eventdetail_details_info span{
    font-size: 1.3rem;
}
.button_style{
    margin: 20px;
}
.apply_button{
    color: #fff!important;
    height: 70px!important;
    font-size: 20px!important;
    /* font-weight: bold; */
    border-radius: 50px!important;
    background-color: rgb(59, 186, 125)!important;
}
.button_style Button{
    height: 55px;
    font-size: 1.2rem;
    border-radius: 50px;
    font-weight: 700;
    background-color: rgb(240,240,240);
}
.eventdetail_details h3{
    text-align: center;
    margin-bottom: 1.5rem;
}
.share_buttons{
    display: flex;
    justify-content: space-evenly;
} 

@media screen and (max-width:64rem) {
    .eventdetail_details{
        margin: 50px 5%;
    }
    .eventdetail_details h2{
        margin-left: 20px;
    }
    .eventdetail_card{
        width: 100vw;
        height: auto;
    }
    .button_style Button{
        width: 80vw;
    }
    .carousel{
        width: 80vw;
    }
}
@media screen and (min-width:64rem){
    .eventdetail{
        margin-top: 20vh!important;
        display: flex;
        justify-content: space-evenly;
        margin: 9vh 3%;
    }
    .eventdetail_details h2{
        margin-left: 20px;
    }
    .organizer{
        margin-bottom: 100px;
    }
    .eventdetail_card{
        border-radius: 15px;
        width:50vw;
        height:360px;
    }
    .button_style Button{
        width: 30vw;
        max-width: 400px;
    }
    .carousel{
        width: 854px;
        height: 480px;
        object-fit: cover;
    }
}
@media screen and (min-width:70rem) {
    .eventdetail{
        margin: 9vh 2%;
    }
    .eventdetail_card{
        width:60vw;
        height:420px;
    }
}
@media screen and (min-width:85.5rem) {
    .eventdetail{
        margin: 9vh 3%;
    }
    .eventdetail_card{
        width:60vw;
        height:500px;
    }
}