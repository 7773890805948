.contribute {
    margin-top: 5.26rem!important;
  }
  .centerElement{
    font-family: 'Nunito','sans-serif'!important;
  }
  .contribute p {
    margin-bottom: 1.5rem;
  }
  .contribute p:last-of-type {
    margin-bottom: 0.5rem;
  }
  .contribute .list {
    margin: 0 auto;
  }
  .contribute .heading {
    text-align: center;
    padding: 2rem 0 0;
    font-family: 'Nunito', 'sans-serif';
    font-weight: bold;
    font-size: 2rem;
  }
  .contribute .volunteer .openRolesHeading {
    font-weight: bold;
    text-align: center;
  }
  .contribute .volunteer .contributeRoleToggle {
    background: none;
    border: none;
    padding: 0.5rem;
    display: block;
    margin: 0 auto 1rem;
    color: #007bff;
  }
  .contribute .volunteer .contributeRoleToggle:hover {
    color: #0058b7;
  }
  .contribute .volunteer .contributeRoleToggle h2 {
    margin: 0;
  }
  .contribute .volunteer .contributeRole {
    margin-top: 1.5rem;
  }
  .contribute .volunteer .contributeRole h2 {
    font-size: 1.75rem;
    font-weight: bold;
    width: auto;
  }
  .contribute .volunteer .contributeRole p {
    margin-bottom: 0.25rem;
  }
  .contribute .volunteer .contributeRole ul {
    margin-bottom: 1.5rem;
  }
  .contribute .volunteer .contributeRole a {
    text-align: center;
  }
  .contribute .volunteer .contributeRole .contributeRoleApply {
    display: block;
    margin: 2rem auto 5rem;
    color: #fff;
    font-family: 'Nunito', 'sans-serif';
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    transition: 0.2s;
    transition-timing-function: ease-in-out;
    padding: 1rem;
    background-color: #4d5eff;
    border-radius: 50px;
    width: 95%;
  }
  .contribute .volunteer .contributeRole .contributeRoleApply:hover {
    background-color: #5767fe;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  .contribute .volunteer .contributeRole .contributeRoleApply:active {
    background-color: #6574f7;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  .contribute .openSourceSoftware h3 {
    font-weight: bold;
  }
  .contribute .openSourceSoftware .openSourceSteps li {
    padding: 0.5rem 0;
  }
  .contribute .openSourceSoftware .openSourceSteps h4 {
    margin-left: -40px;
  }
  .contribute .openSourceSoftware .openSourceSteps h4 {
    margin-top: 2rem;
  }
  .contribute .openSourceSoftware .openSourceSteps .openSourceFirst h4 {
    margin-top: 0;
  }