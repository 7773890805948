/* @media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
} */
.login{
	background-image: url('https://bccompsci.club/_next/static/media/join.66de69ac578eace138ae38c72d877bac.jpg');
	background-position: center;
	background-size: cover;
	background-repeat: none;
}
.endsection , .loginWindow > h2{
    font-family: 'Nunito','sans-serif';
    font-weight: bold;
}
.endsection > a:hover{
	text-decoration: none;
}
.loginWindow > h2{
    font-size: 2.5rem;
}
.login{
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	/* background-image: linear-gradient(to right ,red , blue); */
} 
.Modal_Header{ 
	text-align: center ;
	font-family: 'Nunito' ;
	font-weight: bold ;
	margin : 100px 0;
	color: black !important;
	width: 100% !important;
	height: 100%;
}
.Modal_Close_Btn{  
	position: absolute !important;
	right: 0;
	top: 0;
	background-color: transparent;
	border: none;
}
.Modal_Close_Icon{
	font-size: 40px !important;
	font-weight: bold !important;
}
.Modal_Join_Btn{
	align-content: center;
}
.loginWindow{
	width: 400px;
	height: 450px;
	border-radius: 20px;
	padding-top: 50px;
	background-color: #fff;
	box-shadow: 2px 1px 18px -11px rgba(0,0,0,0.75);
    -webkit-box-shadow: 2px 1px 18px -11px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 1px 18px -11px rgba(0,0,0,0.75);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
} 
.joinNow{
  color: #fff !important;
  font-weight: bold;
  padding: .6rem 1.2rem;
  background-color: #4d5eff;
  border-radius: 50px;
  cursor:pointer;
}
.login-provider-button{
	display: flex;
	align-items: center;
	justify-content: center;
	width: auto;
	height: 3em;
	padding-right: 15px;
	border: 1px solid #995b5b;
	border-radius: 5px;
	margin-bottom: 1em;
	background-color: #ffffff;
	color: #222222;
	cursor: pointer;
	transition: transform 0.5s ease-in-out;
}
.login-provider-button:hover{
	transform: scale(1.05);
	background-color: #4285F4;
	border-color: #4285F4;
	color: #FFFFFF;
}
.login-provider-button > img{
	position: relative;
	left: -5.5px;
	width: 2.8em;
	height: 2.8em;
	background-color: #FFF;
} 
.reportIssue{
	padding: 0 100px;
}
@media screen and (max-width:500px) {
	.loginWindow{
		width: 90vw;
	}
	.login-provider-button{
		width: 100%;
	}
	.reportIssue{
		padding:0 50px;
	}
}