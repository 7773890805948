.highlight {
  margin: 4rem 0;
  padding: 3rem 0;
}
.highlight .highlightContainer {
  margin: 0 auto;
  padding: 0 6.25vw;
  display: flex;
  flex-direction: column;
}
.highlight .highlightContainer .highlightContent {
  padding-left: 1.75vw;
  padding-right: 1.75vw;
}
.highlight .highlightContainer .highlightContent h2 {
  font-family:  'Nunito','sans-serif';
  font-size: 2.5rem;
  font-weight: bold;
}
.highlight .highlightContainer .highlightContent p {
  font-family: 'Nunito', sans-serif;
  font-size: 1.25rem;
  text-align: justify;
}
.highlight .highlightContainer .highlightContent a {
  font-family: 'Nunito', sans-serif;
  font-size: 1.5rem;
  color: #4d5eff;
}
.highlight .highlightContainer .highlightImage {
  margin: 2rem auto;
  height: auto;
  width: 100%;
  background-size: cover;
  background-position: center;
  /* box-shadow: 0 0 25px rgba(0, 0, 0, 0.3); */
  border-radius: 10px;
}
@media screen and (min-width: 43.75rem) {
  .highlight {
    padding: 4rem 0;
  }
  .highlight .highlightContainer {
    padding: 0 5vw;
    align-items: center;
    justify-content: space-between;
  }
  @supports (padding: max(0px)) {
    @media screen and (orientation: landscape) {
      .highlight .highlightContainer {
        padding-left: max(5vw, calc(env(safe-area-inset-left) + 1rem));
        padding-right: max(5vw, calc(env(safe-area-inset-right) + 1rem));
      }
    }
  }
  .highlight .highlightContainer .highlightContent {
    padding-left: 0;
    padding-right: 0;
    width: 47.5%;
  }
  .highlight .highlightContainer .highlightContent h2 {
    font-size: 2.5rem;
  }
  .highlight .highlightContainer .highlightContent a {
    font-size: 1.5rem;
  }
  .highlight .highlightContainer .highlightImage {
    margin: 0;
    width: 47.5%;
    border-radius: 20px;
  }
  .highlight .highlightLearn, .highlight .highlightNetwork {
    flex-direction: row-reverse;
  }
  .highlight .highlightCollaborate {
    flex-direction: row;
  }
}
@media screen and (min-width: 64rem) {
  .highlight .highlightContainer .highlightContent {
    width: 44%;
  }
  .highlight .highlightContainer .highlightContent h2 {
    font-size: 3rem;
  }
  .highlight .highlightContainer .highlightContent p {
    font-size: 1.25rem;
  }
  .highlight .highlightContainer .highlightImage {
    width: 50%;
  }
}
@media screen and (min-width: 85.375rem) {
  .highlight .highlightContainer {
    padding: 0;
    max-width: 76.875rem;
  }
}
