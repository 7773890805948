.CareerComponent{
    margin:50px;
    font-family: 'Ralway',sans-serif !important;
    display: inline-flex;
    flex-wrap: wrap;
}
.jobcard{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
    height: 200px;
    padding: 15px;
    cursor: pointer;
    border-radius: 15px;
    overflow: hidden;
    transition: .3s ease-in-out;
    box-shadow: 2px 3px 10px rgba(0,0,0,0.5);
    margin: 10px;
}
.jobheader{
    display:flex;
}
.companyLogoborder{
    width:60px !important;
    height: 60px !important;
    border-radius: 50% !important;
    overflow: hidden;
}
.companyLogo{
    width: 100%;
    height: 100%;
} 
.jobHeadline , .companyName{
    font-weight: 500 !important;
}
.loc{
    font-size: 16px !important;
}
.applybtn{
    color: #fff!important;
    height: 40px!important;
    font-size: 15px!important;
    border-radius: 50px!important;
    background-color: #4d5eff !important;
}
@media screen and (max-width:500px) {
    .CareerComponent{
        margin: 100px 10vw;
    }
    .jobcard{
        width: 80vw;
        height: auto;
    }

    .companyLogoborder{
        width:45px !important;
        height: 45px !important;
        min-width: 45px !important;
        max-width: 45px !important;
    }
    
}
