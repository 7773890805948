.heading {
    font-size: 2.25rem;
    font-weight: bold;
    font-family: 'Nunito','sans-serif';
    text-align: center;
    margin-bottom: 1rem;
  }
  .meetteam{
    font-family:'Nunito','sans-serif';
  }
  @media screen and (min-width: 43.75rem) {
    .heading {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
  }
  .paragraph, .paragraphNoMargin, .paragraphGroup > p {
    font-family: 'Nunito', sans-serif;
    font-size: 1.25rem;
    text-align: justify;
  }
  .paragraphNoMargin {
    margin: 0;
  }
  .paragraphGroup > p:last-child {
    margin: 0;
  }
  .about .meetteam{
    text-align: center;
    font-weight: bold;
  }
  .applybtn{
    
  }
  