.animatedtext{
  display: flex;
}
/*  fonts : Nunito  Raleway  sans-serif  */
.welcome{
  margin-top: 5.26rem;
  margin-bottom: 2rem;
}
.welcome .welcomeBackgroundContainer {
  background-color: #f8eeff;
  padding: 6.25vw 0;
}
.player-wrapper{
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
} 
.welcome .welcomeContainer {
  margin: 0 auto;
  padding: 0 6.25vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
}
.welcome .welcomeContainer .welcomeContent .slogan {
  margin: 0;
  font-weight: lighter;
  font-size: 1.875rem;
  font-family: 'Nunito','sans-serif';
}
.welcome .welcomeContainer .welcomeContent .slogan .sloganBeginning {
  margin: 0.67em 0;
  font-size: inherit;
  font-weight: 400;
}
.welcome .welcomeContainer .welcomeContent .slogan .typewriterPlaceholder {
  font-weight: bold;
}
.welcome  .welcomeContainer .welcomeContent .welcomeDescription {
  font-size: 1rem;
  font-family: 'Nunito','sans-serif';
  line-height: 1.35;
  text-align: justify;
}
.welcome .welcomeContainer .welcomeContent .welcomeActionButtons {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.welcome .welcomeContainer .welcomeContent .welcomeActionButtons a {
  padding: 1rem;
  text-align: center;
  text-decoration: none;
  font-size: 1.25rem;
  font-family: 'Nunito', sans-serif;
  transition: 0.2s;
  transition-timing-function: ease-in-out;
  width: 95%;
}
.welcome .welcomeContainer .welcomeContent .welcomeActionButtons a.joinButton {
  color: #fff;
  font-weight: bold;
  background-color: #4d5eff;
  border-radius: 50px;
}
.welcome .welcomeContainer .welcomeContent .welcomeActionButtons a.joinButton:hover {
  background-color: #5767fe;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}
.welcome .welcomeContainer .welcomeContent .welcomeActionButtons a.joinButton:active {
  background-color: #6574f7;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
.welcome .welcomeContainer .welcomeContent .welcomeActionButtons a.learnMore {
  cursor: pointer;
  margin-top: 1rem;
  box-shadow: 0 0 0 2px #4d5eff inset;
  color: #4d5eff;
  border-radius: 50px;
  background-color: initial;
}
.welcome .welcomeContainer .welcomeContent .welcomeActionButtons a.learnMore:hover {
  box-shadow: 0 0 0 3px #4d5eff inset;
}
.welcome .welcomeContainer .welcomeContent .welcomeActionButtons a.learnMore:active {
  box-shadow: 0 0 0 2px #4d5eff inset;
}
.welcome .welcomeContainer .welcomeImage {
  margin-top: 2rem;
  max-width: 87.5vw;
}
.welcome .welcomeContainer .welcomeImage img {
  height: auto;
  width: 100%;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
}
@media screen and (min-width: 21.875rem) {
  .welcome .welcomeContainer .welcomeContent .slogan {
    font-size: 2.1rem;
  }
  .welcome .welcomeContainer .welcomeContent .welcomeDescription {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 23.125rem) {
  .player-wrapper {
    width: 87.5vw;
    height: auto;
    padding-top: 56.25%; 
  }
}
@media screen and (min-width: 23.125rem) {
  .welcome .welcomeContainer .welcomeContent .slogan {
    font-size: 2.2rem;
  }
  .player-wrapper {
    width: 87.5vw;
    height: auto;
    padding-top: 56.25%; 
  }
}
@media screen and (min-width: 43.75rem) {
  .welcome .welcomeBackgroundContainer {
    padding: 5.5% 0;
  }
  .player-wrapper {
    width: 40vw;
    height: auto;
    position: relative;
  } 
 
  .welcome .welcomeContainer {
    flex-direction: row;
    padding: 0 5vw;
  }
  @supports (padding: max(0px)) {
    @media screen and (orientation: landscape) {
      .welcome .welcomeContainer {
        padding-left: max(5vw, calc(env(safe-area-inset-left) + 1rem));
        padding-right: max(5vw, calc(env(safe-area-inset-right) + 1rem));
      }
    }
  }
  .welcome .welcomeContainer .welcomeContent {
    max-width: 45%;
  }
  .welcome .welcomeContainer .welcomeContent .slogan {
    font-size: 2.5rem;
  }
  .welcome .welcomeContainer .welcomeContent .welcomeDescription {
    font-size: 1.1rem;
    font-weight: 9999;
    line-height: 1.5;
  }
  .welcome .welcomeContainer .welcomeContent .welcomeActionButtons {
    margin-top: 1.25rem;
  }
  .welcome .welcomeContainer .welcomeContent .welcomeActionButtons a {
    font-size: 1.5rem;
    width: 100%;
  }
  .welcome .welcomeContainer .welcomeImage {
    margin-top: 0;
    max-width: 50%;
  }
  .welcome .welcomeContainer .welcomeImage img {
    border-radius: 20px;
  }
}
@media screen and (min-width: 43.75rem) and (orientation: landscape) {
  .welcome .welcomeBackgroundContainer {
    padding: 4% 0;
  }
}
@media screen and (min-width: 64rem) {
  .welcome .welcomeBackgroundContainer {
    padding: 4% 0;
  }
  .welcome .welcomeContainer .welcomeContent .welcomeActionButtons {
    flex-direction: row;
  }
  .welcome .welcomeContainer .welcomeContent .welcomeActionButtons a.joinButton {
    width: 55%;
  }
  .welcome .welcomeContainer .welcomeContent .welcomeActionButtons a.learnMore {
    margin-top: 0;
    margin-left: 1.25rem;
    width: 45%;
  }
  .welcome .welcomeContainer .welcomeImage {
    max-width: 46.875%;
  }
}
@media screen and (min-width: 68.75rem) {
  .welcome .welcomeContainer .welcomeContent .slogan {
    font-size: 3rem;
  }
  .welcome .welcomeContainer .welcomeContent .welcomeDescription {
    font-size: 1.25rem;
  }
  .welcome .welcomeContainer .welcomeContent .welcomeActionButtons a {
    font-size: 1.75rem;
  }
}
@media screen and (min-width: 80rem) {
  .welcome .welcomeBackgroundContainer {
    padding: 3% 0;
  }
  .welcome .welcomeContainer .welcomeContent {
    max-width: 40%;
  }
  .welcome .welcomeContainer .welcomeImage {
    max-width: 52.5%;
  }
}