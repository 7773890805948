@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar{
    display: none;
}

.App {
  text-align: center;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
}
/* body {
  background-color: #52acff;

} */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #52acff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center!important;
  justify-content: center!important;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-header > form > .row {
  justify-content: center;
}
.App-header > form > .row > span {
  font-family: "Open Sans";
  font-size: 27px;
}

.App > .row {
  justify-content: space-around;
}
.col1 {
  padding: 0px;
  margin: 8px;
  border-radius: 15px;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.15);
  max-width: 37% !important;
}
.col1:hover {
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.15);
}
.col1 > a {
  margin-left: 0;
  margin-top: 0;
  text-decoration: none !important;
  font-size: 33px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.col1 > a > h2 {
  font-size: 2rem;
  margin-top: -7%;
  margin-bottom: 3%;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
}
.App-link {
  color: #61dafb;
}
.upload-btn {
  margin-bottom: 40px !important;
}
/* .App-header button {
   background-color: #52acff;
  font-weight: 500;
  
  width: 100px; 
  margin-bottom: 15px;
} */
.btn2 {
  margin: 50px auto;
}
.btn1 {
  margin: 0 10px;
  width: 100px;
}
.btn1 > a {
  margin-left: 0 !important;
  text-decoration: none !important;
}
.btn1:hover {
  font-weight: 600 !important;
}
.img1 {
  width: 100%;
}
#register input[type="text"],
#register textarea,
#register select {
  background-color: #f0f0f0;
}

.App-header > form > .row > .btn {
  margin: 0;
  height: 38px;
}
.App-header > h2,
.App-header > h4 {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.google-sign > h6 {
  font-size: 15px;
}
.makeStyles-paper-1 {
  border: none;
  border-radius: 18px;
}
@media only screen and (max-width: 600px) {
  .col1 {
    max-width: auto;
    max-width: 70% !important;
    margin-left: 0%;
    margin-bottom: 3%;
  }
  .App > .row {
    justify-content: center;
  }
  .App {
    margin-top: 22px;
    margin-bottom: 0;
  }
  .App-header > form > .row > span {
    margin-right: 0px !important;
    width: auto;
    text-align: right;
  }
  .App-header > form > div > input {
    width: 215px !important;
  }
  .App-header > h2 {
    font-size: 29px;
  }
  .App-logo {
    height: 47vmin;
  }
  .App-header > form > div > .bluearrow {
    margin-top: 20px !important;
  }
}

body,
html {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
/* dark-bg{
  background-color: #282c34;
   background-color: 
} */

/* body { */
  /* background: #aecad8; */
  /* background-color: #282c34; */
  /* background: #fff; */
  /* transition: all 0.14s ease-in; */
/* } */

.container {
  margin: auto;
  width: 212px;
}

/* .power {
  margin: auto;
  margin-top: 20px;
  background: #38434A;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-family: Roboto;
  font-size: 1.9em;
  height: 65px;
  letter-spacing: 0.2px;
  line-height: 65px;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 150px;
  transition: none;
} */
/* .power:hover {
  background: #414e56;
} */
/* .power:active {
  box-shadow: inset 0px 4px 10px 0px #2d363b;
  transition: background 0.14s ease-in;
} */
/* .active .power {
  background: #58C48D;
} */
/* .active .power:active {
  box-shadow: inset 0px 6px 8px -1px #41b77b;
} */

#on {
  -webkit-transform: translate(50%, 50%) scale(0);
          transform: translate(50%, 50%) scale(0);
  opacity: 0;
}
.active #on {
  opacity: 1;
  -webkit-transform: translate(0) scale(1);
          transform: translate(0) scale(1);
  transition: all 0.14s ease-in;
}

.fancy-bulb {
  position: relative;
  margin: auto;
  top: 25%;
  margin-top: 200px;
}

.streaks,
.streaks:after,
.streaks:before {
  position: absolute;
  background: #ffdb55;
  border-radius: 5.5px;
  height: 11px;
}

.streaks:after,
.streaks:before {
  content: "";
  display: block;
}

.streaks:before {
  bottom: 65px;
}

.streaks:after {
  top: 65px;
}

.left-streaks {
  right: 217px;
  top: 89.5px;
}
.active .left-streaks {
  -webkit-animation: move-left 0.38s ease-out, width-to-zero 0.38s ease-out;
  animation: move-left 0.38s ease-out, width-to-zero 0.38s ease-out;
  -webkit-animation-delay: 0.14s;
  animation-delay: 0.14s;
}
.left-streaks:before,
.left-streaks:after {
  left: 15px;
}
.active .left-streaks:before {
  -webkit-animation: width-to-zero 0.38s ease-out, move-up 0.38s ease-out;
  animation: width-to-zero 0.38s ease-out, move-up 0.38s ease-out;
  -webkit-animation-delay: 0.14s;
  animation-delay: 0.14s;
}
.active .left-streaks:after {
  -webkit-animation: width-to-zero 0.38s ease-out, move-down 0.38s ease-out;
  animation: width-to-zero 0.38s ease-out, move-down 0.38s ease-out;
  -webkit-animation-delay: 0.14s;
  animation-delay: 0.14s;
}

.right-streaks {
  left: 217px;
  top: 89.5px;
}
.active .right-streaks {
  -webkit-animation: move-right 0.38s ease-out, width-to-zero 0.38s ease-out;
  animation: move-right 0.38s ease-out, width-to-zero 0.38s ease-out;
  -webkit-animation-delay: 0.14s;
  animation-delay: 0.14s;
}
.right-streaks:before,
.right-streaks:after {
  right: 15px;
}
.active .right-streaks:before {
  -webkit-animation: width-to-zero 0.38s ease-out, move-up 0.38s ease-out;
  animation: width-to-zero 0.38s ease-out, move-up 0.38s ease-out;
  -webkit-animation-delay: 0.14s;
  animation-delay: 0.14s;
}
.active .right-streaks:after {
  -webkit-animation: width-to-zero 0.38s ease-out, move-down 0.38s ease-out;
  animation: width-to-zero 0.38s ease-out, move-down 0.38s ease-out;
  -webkit-animation-delay: 0.14s;
  animation-delay: 0.14s;
}

.left-streaks:before,
.right-streaks:after {
  -webkit-transform: rotate(34deg);
          transform: rotate(34deg);
}

.left-streaks:after,
.right-streaks:before {
  -webkit-transform: rotate(-34deg);
          transform: rotate(-34deg);
}

@-webkit-keyframes move-left {
  0% {
    -webkit-transform: none;
            transform: none;
  }
  65% {
    -webkit-transform: translateX(-80px);
            transform: translateX(-80px);
  }
  100% {
    -webkit-transform: translateX(-80px);
            transform: translateX(-80px);
  }
}

@keyframes move-left {
  0% {
    -webkit-transform: none;
            transform: none;
  }
  65% {
    -webkit-transform: translateX(-80px);
            transform: translateX(-80px);
  }
  100% {
    -webkit-transform: translateX(-80px);
            transform: translateX(-80px);
  }
}
@-webkit-keyframes move-right {
  0% {
    -webkit-transform: none;
            transform: none;
  }
  65% {
    -webkit-transform: translateX(80px);
            transform: translateX(80px);
  }
  100% {
    -webkit-transform: translateX(80px);
            transform: translateX(80px);
  }
}
@keyframes move-right {
  0% {
    -webkit-transform: none;
            transform: none;
  }
  65% {
    -webkit-transform: translateX(80px);
            transform: translateX(80px);
  }
  100% {
    -webkit-transform: translateX(80px);
            transform: translateX(80px);
  }
}
@-webkit-keyframes width-to-zero {
  0% {
    width: 50px;
  }
  100% {
    width: 11px;
  }
}
@keyframes width-to-zero {
  0% {
    width: 50px;
  }
  100% {
    width: 11px;
  }
}
@-webkit-keyframes move-up {
  100% {
    bottom: 100.75px;
  }
}
@keyframes move-up {
  100% {
    bottom: 100.75px;
  }
}
@-webkit-keyframes move-down {
  100% {
    top: 100.75px;
  }
}
@keyframes move-down {
  100% {
    top: 100.75px;
  }
}

.animatedtext{
  display: flex;
}
/*  fonts : Nunito  Raleway  sans-serif  */
.welcome{
  margin-top: 5.26rem;
  margin-bottom: 2rem;
}
.welcome .welcomeBackgroundContainer {
  background-color: #f8eeff;
  padding: 6.25vw 0;
}
.player-wrapper{
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
} 
.welcome .welcomeContainer {
  margin: 0 auto;
  padding: 0 6.25vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
}
.welcome .welcomeContainer .welcomeContent .slogan {
  margin: 0;
  font-weight: lighter;
  font-size: 1.875rem;
  font-family: 'Nunito','sans-serif';
}
.welcome .welcomeContainer .welcomeContent .slogan .sloganBeginning {
  margin: 0.67em 0;
  font-size: inherit;
  font-weight: 400;
}
.welcome .welcomeContainer .welcomeContent .slogan .typewriterPlaceholder {
  font-weight: bold;
}
.welcome  .welcomeContainer .welcomeContent .welcomeDescription {
  font-size: 1rem;
  font-family: 'Nunito','sans-serif';
  line-height: 1.35;
  text-align: justify;
}
.welcome .welcomeContainer .welcomeContent .welcomeActionButtons {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.welcome .welcomeContainer .welcomeContent .welcomeActionButtons a {
  padding: 1rem;
  text-align: center;
  text-decoration: none;
  font-size: 1.25rem;
  font-family: 'Nunito', sans-serif;
  transition: 0.2s;
  transition-timing-function: ease-in-out;
  width: 95%;
}
.welcome .welcomeContainer .welcomeContent .welcomeActionButtons a.joinButton {
  color: #fff;
  font-weight: bold;
  background-color: #4d5eff;
  border-radius: 50px;
}
.welcome .welcomeContainer .welcomeContent .welcomeActionButtons a.joinButton:hover {
  background-color: #5767fe;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}
.welcome .welcomeContainer .welcomeContent .welcomeActionButtons a.joinButton:active {
  background-color: #6574f7;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
.welcome .welcomeContainer .welcomeContent .welcomeActionButtons a.learnMore {
  cursor: pointer;
  margin-top: 1rem;
  box-shadow: 0 0 0 2px #4d5eff inset;
  color: #4d5eff;
  border-radius: 50px;
  background-color: transparent;
  background-color: initial;
}
.welcome .welcomeContainer .welcomeContent .welcomeActionButtons a.learnMore:hover {
  box-shadow: 0 0 0 3px #4d5eff inset;
}
.welcome .welcomeContainer .welcomeContent .welcomeActionButtons a.learnMore:active {
  box-shadow: 0 0 0 2px #4d5eff inset;
}
.welcome .welcomeContainer .welcomeImage {
  margin-top: 2rem;
  max-width: 87.5vw;
}
.welcome .welcomeContainer .welcomeImage img {
  height: auto;
  width: 100%;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
}
@media screen and (min-width: 21.875rem) {
  .welcome .welcomeContainer .welcomeContent .slogan {
    font-size: 2.1rem;
  }
  .welcome .welcomeContainer .welcomeContent .welcomeDescription {
    font-size: 1.125rem;
  }
}
@media screen and (max-width: 23.125rem) {
  .player-wrapper {
    width: 87.5vw;
    height: auto;
    padding-top: 56.25%; 
  }
}
@media screen and (min-width: 23.125rem) {
  .welcome .welcomeContainer .welcomeContent .slogan {
    font-size: 2.2rem;
  }
  .player-wrapper {
    width: 87.5vw;
    height: auto;
    padding-top: 56.25%; 
  }
}
@media screen and (min-width: 43.75rem) {
  .welcome .welcomeBackgroundContainer {
    padding: 5.5% 0;
  }
  .player-wrapper {
    width: 40vw;
    height: auto;
    position: relative;
  } 
 
  .welcome .welcomeContainer {
    flex-direction: row;
    padding: 0 5vw;
  }
  @supports (padding: max(0px)) {
    @media screen and (orientation: landscape) {
      .welcome .welcomeContainer {
        padding-left: max(5vw, calc(env(safe-area-inset-left) + 1rem));
        padding-right: max(5vw, calc(env(safe-area-inset-right) + 1rem));
      }
    }
  }
  .welcome .welcomeContainer .welcomeContent {
    max-width: 45%;
  }
  .welcome .welcomeContainer .welcomeContent .slogan {
    font-size: 2.5rem;
  }
  .welcome .welcomeContainer .welcomeContent .welcomeDescription {
    font-size: 1.1rem;
    font-weight: 9999;
    line-height: 1.5;
  }
  .welcome .welcomeContainer .welcomeContent .welcomeActionButtons {
    margin-top: 1.25rem;
  }
  .welcome .welcomeContainer .welcomeContent .welcomeActionButtons a {
    font-size: 1.5rem;
    width: 100%;
  }
  .welcome .welcomeContainer .welcomeImage {
    margin-top: 0;
    max-width: 50%;
  }
  .welcome .welcomeContainer .welcomeImage img {
    border-radius: 20px;
  }
}
@media screen and (min-width: 43.75rem) and (orientation: landscape) {
  .welcome .welcomeBackgroundContainer {
    padding: 4% 0;
  }
}
@media screen and (min-width: 64rem) {
  .welcome .welcomeBackgroundContainer {
    padding: 4% 0;
  }
  .welcome .welcomeContainer .welcomeContent .welcomeActionButtons {
    flex-direction: row;
  }
  .welcome .welcomeContainer .welcomeContent .welcomeActionButtons a.joinButton {
    width: 55%;
  }
  .welcome .welcomeContainer .welcomeContent .welcomeActionButtons a.learnMore {
    margin-top: 0;
    margin-left: 1.25rem;
    width: 45%;
  }
  .welcome .welcomeContainer .welcomeImage {
    max-width: 46.875%;
  }
}
@media screen and (min-width: 68.75rem) {
  .welcome .welcomeContainer .welcomeContent .slogan {
    font-size: 3rem;
  }
  .welcome .welcomeContainer .welcomeContent .welcomeDescription {
    font-size: 1.25rem;
  }
  .welcome .welcomeContainer .welcomeContent .welcomeActionButtons a {
    font-size: 1.75rem;
  }
}
@media screen and (min-width: 80rem) {
  .welcome .welcomeBackgroundContainer {
    padding: 3% 0;
  }
  .welcome .welcomeContainer .welcomeContent {
    max-width: 40%;
  }
  .welcome .welcomeContainer .welcomeImage {
    max-width: 52.5%;
  }
}
.ending {
  background-color: #4d5eff;
}
.ending .endingContainer {
  display: flex;
  flex-direction: column;
  margin: 4rem auto 0;
  padding: 5rem 6.25vw;
}
.ending .endingContainer h2 {
  margin: 0;
  text-align: center;
  color: #fff;
  font-size: 1.5rem;
  line-height: 1.25;
  font-weight: bold;
  font-family: 'Nunito', sans-serif;
}
.ending .endingContainer a {
  display: block;
  margin: 3rem auto 0;
  text-align: center;
  text-decoration: none;
  font-family: 'Nunito', sans-serif;
  font-size: 1.25rem;
  transition: 0.2s;
  transition-timing-function: ease-in-out;
  padding: 1rem;
  width: 100%;
  color: #4d5eff;
  font-weight: bold;
  background-color: #fff;
  border-radius: 50px;
}
.ending .endingContainer a:hover {
  background-color: #dae0fe;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}
.ending .endingContainer a:active {
  background-color: #ebefff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
@media screen and (min-width: 43.75rem) {
  .ending .endingContainer {
    margin: 0 auto;
    max-width: 76.875rem;
  }
  .ending .endingContainer h2 {
    font-size: 2.2rem;
    line-height: normal;
    line-height: initial;
  }
  .ending .endingContainer a {
    font-size: 1.5rem;
    padding: 1rem 2.5rem;
    width: auto;
  }
}
@media screen and (min-width: 64rem) {
  .ending .endingContainer h2 {
    font-size: 2.5rem;
  }
  .ending .endingContainer a {
    font-size: 1.75rem;
  }
}

.description {
  margin: 0 5%;
}
.description p {
  font-family:'Nunito','sans-serif';
}
.description h2 {
  font-family: 'Nunito','sans-serif';
  font-size: 2.25rem;
}
.description .descriptionTop {
  margin: 0 auto;
  padding: 2.5rem var(--content-side-padding-value) 0;
}
.description .descriptionTop .descriptionHeading {
  margin-bottom: 1rem;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
}
.description .descriptionTop .descriptionOpeningParagraph {
  margin: 0 auto;
  font-size: 1.2rem;
  text-align: center;
}
.description .descriptionTop .descriptionImage {
  display: block;
  margin: 2rem auto;
  height: auto;
  width: 100%;
  background-size: cover;
  border-radius: 10px;
}
.description .descriptionTop .descriptionMission {
  margin: 4rem auto;
  text-align: center;
}
.description .descriptionTop .descriptionMission .descriptionMissionHeading {
  font-family:  'Nunito','sans-serif';
  font-weight: bold;
  font-size: 2.275rem;
}
.description .descriptionTop .descriptionMission .missionContent {
  font-size: 1.3rem;
  font-weight: bold;
}
.description .descriptionMain {
  padding: 0 8vw;
}
.description .descriptionMain p {
  margin: 1rem auto;
  font-size: 1.2rem;
  text-align: justify;
}
@media screen and (orientation: landscape) {
  .description h2 {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 23.125rem) {
  .description h2 {
    font-size: 2.5rem;
  }
  .description .descriptionTop .descriptionMission .descriptionMissionHeading {
    font-size: 2.625rem;
  }
  .description .descriptionTop .descriptionMission .missionContent {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 23.125rem) and (orientation: landscape) {
  .description .descriptionTop .descriptionMission .descriptionMissionHeading {
    font-size: 2.3625rem;
  }
  .description .descriptionTop .descriptionMission .missionContent {
    font-size: 1.35rem;
  }
}
@media screen and (min-width: 43.75rem) {
  .description .descriptionTop {
    padding: 4rem 6.25vw 0;
  }
  @supports (padding: max(0px)) {
    @media screen and (orientation: landscape) {
      .description .descriptionTop {
        padding-left: max(6.25vw, calc(env(safe-area-inset-left)));
        padding-right: max(6.25vw, calc(env(safe-area-inset-right)));
      }
    }
  }
  .description .descriptionTop .descriptionHeading {
    font-weight: 400;
  }
  .description .descriptionTop .descriptionImage {
    border-radius: 15px;
  }
  .description .descriptionMain {
    padding: 0 10vw;
  }
  @supports (padding: max(0px)) {
    @media screen and (orientation: landscape) {
      .description .descriptionMain {
        padding-left: max(10vw, calc(env(safe-area-inset-left) + 10vw));
        padding-right: max(10vw, calc(env(safe-area-inset-right) + 10vw));
      }
    }
  }
}
@media screen and (min-width: 43.75rem) and (orientation: landscape) {
  .description .descriptionTop .descriptionImageContainer {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
  .description .descriptionTop .descriptionMission .descriptionMissionHeading {
    font-size: 2.5375rem;
  }
  .description .descriptionTop .descriptionMission .missionContent {
    font-size: 1.45rem;
  }
}
@media screen and (min-width: 48rem) {
  .description .descriptionTop .descriptionMission .descriptionMissionHeading {
    font-size: 2.8rem;
  }
  .description .descriptionTop .descriptionMission .missionContent {
    font-size: 1.6rem;
  }
}
@media screen and (min-width: 64rem) {
  .description .descriptionTop {
    padding: 4rem 0 0;
  }
  .description .descriptionTop .descriptionImage {
    max-width: 52rem;
  }
  .description .descriptionTop .descriptionMission .descriptionMissionHeading {
    font-size: 2.975rem;
  }
  .description .descriptionTop .descriptionMission .missionContent {
    font-size: 1.7rem;
  }
  .description .descriptionMain p {
    padding: 0;
    max-width: 42rem;
  }
}
@media screen and (min-width: 85.375rem) {
  .description {
    max-width: var(--large-screen-max-width);
  }
}

.highlight {
  margin: 4rem 0;
  padding: 3rem 0;
}
.highlight .highlightContainer {
  margin: 0 auto;
  padding: 0 6.25vw;
  display: flex;
  flex-direction: column;
}
.highlight .highlightContainer .highlightContent {
  padding-left: 1.75vw;
  padding-right: 1.75vw;
}
.highlight .highlightContainer .highlightContent h2 {
  font-family:  'Nunito','sans-serif';
  font-size: 2.5rem;
  font-weight: bold;
}
.highlight .highlightContainer .highlightContent p {
  font-family: 'Nunito', sans-serif;
  font-size: 1.25rem;
  text-align: justify;
}
.highlight .highlightContainer .highlightContent a {
  font-family: 'Nunito', sans-serif;
  font-size: 1.5rem;
  color: #4d5eff;
}
.highlight .highlightContainer .highlightImage {
  margin: 2rem auto;
  height: auto;
  width: 100%;
  background-size: cover;
  background-position: center;
  /* box-shadow: 0 0 25px rgba(0, 0, 0, 0.3); */
  border-radius: 10px;
}
@media screen and (min-width: 43.75rem) {
  .highlight {
    padding: 4rem 0;
  }
  .highlight .highlightContainer {
    padding: 0 5vw;
    align-items: center;
    justify-content: space-between;
  }
  @supports (padding: max(0px)) {
    @media screen and (orientation: landscape) {
      .highlight .highlightContainer {
        padding-left: max(5vw, calc(env(safe-area-inset-left) + 1rem));
        padding-right: max(5vw, calc(env(safe-area-inset-right) + 1rem));
      }
    }
  }
  .highlight .highlightContainer .highlightContent {
    padding-left: 0;
    padding-right: 0;
    width: 47.5%;
  }
  .highlight .highlightContainer .highlightContent h2 {
    font-size: 2.5rem;
  }
  .highlight .highlightContainer .highlightContent a {
    font-size: 1.5rem;
  }
  .highlight .highlightContainer .highlightImage {
    margin: 0;
    width: 47.5%;
    border-radius: 20px;
  }
  .highlight .highlightLearn, .highlight .highlightNetwork {
    flex-direction: row-reverse;
  }
  .highlight .highlightCollaborate {
    flex-direction: row;
  }
}
@media screen and (min-width: 64rem) {
  .highlight .highlightContainer .highlightContent {
    width: 44%;
  }
  .highlight .highlightContainer .highlightContent h2 {
    font-size: 3rem;
  }
  .highlight .highlightContainer .highlightContent p {
    font-size: 1.25rem;
  }
  .highlight .highlightContainer .highlightImage {
    width: 50%;
  }
}
@media screen and (min-width: 85.375rem) {
  .highlight .highlightContainer {
    padding: 0;
    max-width: 76.875rem;
  }
}

.highlights {
  margin: 5rem auto 0;
}

header{
  margin-bottom: 8vh;
}
.navbarMain {
  position: fixed;
  padding: 0.75rem 0;
  width: 100%;
  z-index: 900;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}
.navbarMain .navbarMainItems {
  margin: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
}
.navbarMain .navbarMainItems .navLogo a {
  display: flex;
  text-decoration: none;
  align-items: center;
}
.logo {
  height: 4rem;
  width: 4rem;
}
.MenuItem{
  font-family: 'Nunito', sans-serif !important;
}
.navbarMain .navbarMainItems .navLogo a p {
  margin: 0 0 0 0.5rem;
  font-size: 1rem;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  color: #000;
  line-height: 1.2;
}

@media screen and (max-width: 64rem) {
    .openmenu{
      display:none;
    }
  .navbarMain .navbarMainItems .navbarMainLinks {
    display:block;
    min-width: 350px;
    max-width: 350px;
    border-radius: 30px;
    background-color: #fff;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 9999;
    transition: .3s ease-in-out;
    }
    .showbackshadow{
      position: absolute;
      left: 0;
      top: 0;
      width:100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.4);
    }
    .backshadow{
      display: none;
    }
  .showsidebar{
    right: -20px!important;
  }
  .hidesidebar{
    right: -350px!important;
  }
  .showmenuicon{
    display: block!important;
  }
  .hidemenuicon{
    display :none!important;
  }
  .sidebar{
    padding-top: 3vh;
  }
  .sidebar_bottom{
    position: absolute;
    bottom: 10vh;
  }
  .sidebar_bottom li{
    width: 100%;
  }
  .sidebar_bottom li a{
    text-align: center;
    width: 100%;
  }
  .sidebar li{
    list-style-type: none;
    margin-right: 40px;
    padding: 0;
    display: flex;
    font-size: 1.6rem;
    padding-top: 1vh;
    padding-bottom: 1vh;
  }
  .sidebar a{
    color:#000;
    text-decoration: none!important;
    transition:.3s ease-in-out;
  }
  .sidebar a :hover{
    color: #4d5eff!important;
  }
  .sidebar .closeIcon{
    display: flex;
    padding-bottom: 30px;
    justify-content: space-between;
  }
  .sidebar .closeIcon img:hover{
      cursor: pointer;
  }
.sidebarLogo img{
  width: 4rem;
  height: 4rem;
  }
.sidebarLogo small{
  font-weight: bold;
  color: #000;
  padding: 3px;
  font-size: 1.2rem !important;
  }
.sidebarLogo small:hover{
  color: #000!important;
  }
.sidebarLogo Link{
  font-size: 16px;
  }
}
.navbarMain .navbarMainItems .navbarMainLinks .joinButton a {
  color: #fff;
  font-weight: 600;
  padding: .6rem 1.2rem;
  background-color: #4d5eff;
  border-radius: 50px;
  transition: 0.2s;
  transition-timing-function: ease-in-out;
}
.navbarMain .navbarMainItems .navbarMainLinks .joinButton a:hover {
  color: #fff;
  background-color: #4d5eff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  text-decoration: none;
}
.navbarMain .navbarMainItems .navbarMainLinks .joinButton a:active {
  color: #fff;
  background-color: #6574f7;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
.navbarMain .navbarMainItems .navbarHamburgerMenuButton {
  display: block;
  border: none;
  background: none;
  padding: 0;
}
.navbarMain .navbarMainItems .navbarHamburgerMenuButton svg {
  width: 2rem;
  height: 2rem;
}

@media screen and (min-width: 64rem) {
  .navbarMain .navbarMainItems {
    max-width: 80rem;
    width: 95vw;
    margin: 0 auto;
  } 
  .navbarMain .navbarMainItems .navbarHamburgerMenuButton {
    display: none;
  }
  /* .navbarMain .navbarMainItems .navLogo a img {
    height: 3rem;
    width: 3rem;
  } */
  .navbarMain .navbarMainItems .navLogo a p {
    margin: 0 0 0 0.5rem;
    font-size: 1rem;
    line-height: 1.25;
  }
  .navbarMain .navbarMainItems .navbarMainLinks {
    display: inline;
    display: initial;
  }
  .navbarMain .navbarMainItems .navbarMainLinks ul{
    margin: 0;
    display: flex;
    align-items: center;
    font-family: 'Nunito','sans-serif';
  }
  .sidebar div{
    display: flex;
  }
  .navbarMain .navbarMainItems .navbarMainLinks ul li {
    list-style: none;
  }
  .navbarMain .navbarMainItems .navbarMainLinks ul li:not(:last-child) a {
    font-size: 1rem;
    color: #000;
    padding: 0 0.75rem;
    margin: 0 0.1rem;
    text-decoration: none;
    transition: 0.1s;
    transition-timing-function: ease;
  }
  .navbarMain .navbarMainItems .navbarMainLinks ul li:not(:last-child) a:hover {
    color: #4053fe;
  }
  .navbarMain .navbarMainItems .navbarMainLinks ul li.userIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
  }
  .navbarMain .navbarMainItems .navbarMainLinks ul li.userIcon button {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    background: none;
  }
  .closeIcon{
    display: none;
  }
}

.dropdownArrow {
  display: block;
  margin-left: 0.5rem;
}

.footer {
  background-color: #232a67;
}
.footerContainer {
  padding: 1rem 0;
}
.footerContainer p {
  font-size: 1rem;
  margin: 0;
  color: #fff;
  text-align: center;
  font-family: 'Nunito', sans-serif;
}
.footerContainer a {
  color: #94d6ff;
}
.footerContainer div .icons{
  margin: 4px;
  cursor: pointer;
  color: #fff;
}

/* .instagram{

} */

/* .youtube{
  color: #f00 !important;
}
.linkedin{
  color: #007bff !important;
}
.twitter{
  color:rgb(29, 161, 242)!important;
} */

.resources{
  margin-top: 5.26rem!important;
}
.resources p {
  margin-bottom: 1rem;
}
.resources h2 {
  text-align: left;
  font-size: 2rem;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 0;
}
.resources h3, .resources h4 {
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
}
.resources h3 {
  margin-top: 1.75rem;
  margin-bottom: 1rem;
}
.resources h4 {
  margin-top: 1rem;
  font-size: 1.25rem;
}
.resources li {
  margin-top: 0.5rem;
}
.resources .contribute {
  margin-top: 2rem;
  padding: 1.5rem;
}
.resources .contribute .alertHeading {
  font-weight: bold;
  font-size: 1.75rem;
}
.resources .contribute p {
  margin-bottom: 0;
}

.container, .expandedWidthContainer {
    margin: 0 auto;
    padding: 4vw;
    width: 100vw !important;
    font-size: 1.25rem;
    font-family: 'Nunito', sans-serif;
    min-height: 100vh;
  }
  @media screen and (min-width: 43.75rem) {
    .container, .expandedWidthContainer {
      margin: 0 auto 20%;
      padding: 3rem 8vw;
    }
    @supports (padding: max(0px)) {
      @media screen and (orientation: landscape) {
        .container, .expandedWidthContainer {
          padding-left: max(8vw, calc(env(safe-area-inset-left) + 8vw));
          padding-right: max(8vw, calc(env(safe-area-inset-right) + 8vw));
        }
      }
    }
  }
  @media screen and (min-width: 64rem) {
    .container, .expandedWidthContainer {
      margin: 0 auto 20%;
      padding: 3rem 0;
      max-width: 53.8125rem;
    }
  }
  @media screen and (min-width: 1920px) {
    .container, .expandedWidthContainer {
      margin: 0 auto 12.5%;
    }
  }
  @media screen and (min-width: 2560px) {
    .container, .expandedWidthContainer {
      margin: 0 auto 25%;
    }
  }
  @media screen and (min-width: 3840px) {
    .container, .expandedWidthContainer {
      margin: 0 auto 35%;
    }
  }
  @media screen and (min-width: 64rem) {
    .expandedWidthContainer {
      max-width: none;
      max-width: initial;
      margin: auto 5vw;
    }
  }
  @media screen and (min-width: 1280px) {
    .expandedWidthContainer {
      max-width: 1200px;
      margin: 0 auto;
    }
  }
  .text h1 {
    margin: auto 8vw;
    font-size: 2.5rem;
    font-weight: bold;
    font-family: 'Raleway', sans-serif;
  }
  .text h2 {
    font-family: 'Raleway', sans-serif;
    font-size: 2rem;
    margin-bottom: 2.5vh;
  }
  .text p {
    font-family: 'Nunito', sans-serif;
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
  @media screen and (min-width: 43.75rem) {
    .text h1 {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
    .text h2 {
      font-size: 2.5rem;
      margin: 0 auto 2.5rem;
    }
    .text p {
      margin-bottom: 2.5rem;
    }
  }
  .centerElement {
    margin: 0 auto;
    text-align: center;
  }
  .emoji {
    margin: 0 auto;
    display: block;
    text-align: center;
    font-size: 5rem;
  } 
  @media screen and (min-width: 43.75rem) {
    .actionButton {
      margin: 0 auto;
      width: 35%;
    }
  }
  .marginTopOneRem {
    margin-top: 1rem;
  }
  .marginTopOnePointFiveRem {
    margin-top: 1.5rem;
  }
  .marginTopTwoRem {
    margin-top: 2rem;
  }
  
.contribute {
    margin-top: 5.26rem!important;
  }
  .centerElement{
    font-family: 'Nunito','sans-serif'!important;
  }
  .contribute p {
    margin-bottom: 1.5rem;
  }
  .contribute p:last-of-type {
    margin-bottom: 0.5rem;
  }
  .contribute .list {
    margin: 0 auto;
  }
  .contribute .heading {
    text-align: center;
    padding: 2rem 0 0;
    font-family: 'Nunito', 'sans-serif';
    font-weight: bold;
    font-size: 2rem;
  }
  .contribute .volunteer .openRolesHeading {
    font-weight: bold;
    text-align: center;
  }
  .contribute .volunteer .contributeRoleToggle {
    background: none;
    border: none;
    padding: 0.5rem;
    display: block;
    margin: 0 auto 1rem;
    color: #007bff;
  }
  .contribute .volunteer .contributeRoleToggle:hover {
    color: #0058b7;
  }
  .contribute .volunteer .contributeRoleToggle h2 {
    margin: 0;
  }
  .contribute .volunteer .contributeRole {
    margin-top: 1.5rem;
  }
  .contribute .volunteer .contributeRole h2 {
    font-size: 1.75rem;
    font-weight: bold;
    width: auto;
  }
  .contribute .volunteer .contributeRole p {
    margin-bottom: 0.25rem;
  }
  .contribute .volunteer .contributeRole ul {
    margin-bottom: 1.5rem;
  }
  .contribute .volunteer .contributeRole a {
    text-align: center;
  }
  .contribute .volunteer .contributeRole .contributeRoleApply {
    display: block;
    margin: 2rem auto 5rem;
    color: #fff;
    font-family: 'Nunito', 'sans-serif';
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    transition: 0.2s;
    transition-timing-function: ease-in-out;
    padding: 1rem;
    background-color: #4d5eff;
    border-radius: 50px;
    width: 95%;
  }
  .contribute .volunteer .contributeRole .contributeRoleApply:hover {
    background-color: #5767fe;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  .contribute .volunteer .contributeRole .contributeRoleApply:active {
    background-color: #6574f7;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  .contribute .openSourceSoftware h3 {
    font-weight: bold;
  }
  .contribute .openSourceSoftware .openSourceSteps li {
    padding: 0.5rem 0;
  }
  .contribute .openSourceSoftware .openSourceSteps h4 {
    margin-left: -40px;
  }
  .contribute .openSourceSoftware .openSourceSteps h4 {
    margin-top: 2rem;
  }
  .contribute .openSourceSoftware .openSourceSteps .openSourceFirst h4 {
    margin-top: 0;
  }
.top {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(/static/media/about-header-desktop.4b6f3bb0.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 5.28rem!important;
}
.top .topContainer {
  padding: 10% 6.25vw;
  display: flex;
  flex-direction: column;
}
.top h1 {
  margin: 0 auto 12.5%;
  color: #fff;
  font-family: 'Nunito', sans-serif;
  font-size: 2.25rem;
  font-weight: 400;
  text-align: center;
}
@media screen and (max-width: 22rem) {
  .top h1 {
    font-size: 1.75rem;
  }
}
.top .joinButton {
  margin: 12.5% auto 0;
  color: #fff;
  font-family: 'Nunito', sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  transition: 0.2s;
  transition-timing-function: ease-in-out;
  padding: 1rem;
  background-color: #4d5eff;
  border-radius: 50px;
  width: 95%;
}
.top .joinButton:hover {
  background-color: #5767fe;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}
.top .joinButton:active {
  background-color: #6574f7;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
@media screen and (orientation: landscape) {
  .top {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(/static/media/about-header-desktop.4b6f3bb0.jpg);
  }
  .top .topContainer {
    padding: 5vw 6.25vw;
  }
  .top h1 {
    margin: 0 auto 5%;
    font-size: 2.4rem;
  }
  .top .joinButton {
    margin: 5% auto 0;
    font-size: 1.25rem;
    width: auto;
    padding: 1rem 2.5rem;
  }
}
@media screen and (min-width: 43.75rem) {
  .top {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(/static/media/about-header-desktop.4b6f3bb0.jpg);
  }
  .top h1 {
    margin-bottom: 7.5%;
    font-size: 2.5rem;
  }
  .top .joinButton {
    margin: 7.5% auto 0;
    font-size: 1.75rem;
    width: auto;
    padding: 1rem 2.5rem;
  }
}
@media screen and (min-width: 43.75rem) and (orientation: landscape) {
  .top {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(/static/media/about-header-desktop.4b6f3bb0.jpg);
    padding: 0;
  }
  .top .topContainer {
    padding: 5vw 6.25vw;
  }
  .top h1 {
    margin: 0 auto 5%;
    font-size: 2.75rem;
  }
  .top .joinButton {
    margin: 5% auto 0;
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 64rem) {
  .top h1 {
    margin-bottom: 5%;
    font-size: 3.5rem;
  }
  .top .joinButton {
    margin-top: 5%;
    font-size: 1.75rem;
  }
}
@media screen and (min-width: 68.75rem) {
  .top h1 {
    font-size: 4rem;
    margin-bottom: 5%;
  }
  .top .joinButton {
    margin-top: 5%;
    font-size: 1.75rem;
  }
}

.whoWeAre {
  padding: 2.75rem 8vw 3rem;
  margin: 0 auto;
}
@media screen and (min-width: 43.75rem) {
  .whoWeAre {
    padding: 2.75rem 10vw 3rem;
  }
  @supports (padding: max(0px)) {
    @media screen and (orientation: landscape) {
      .whoWeAre {
        padding-left: max(10vw, calc(env(safe-area-inset-left) + 10vw));
        padding-right: max(10vw, calc(env(safe-area-inset-right) + 10vw));
      }
    }
  }
}
@media screen and (min-width: 64rem) {
  .whoWeAre {
    padding: 2.75rem 0 3rem;
    max-width: 48rem;
  }
}

.heading {
    font-size: 2.25rem;
    font-weight: bold;
    font-family: 'Nunito','sans-serif';
    text-align: center;
    margin-bottom: 1rem;
  }
  .meetteam{
    font-family:'Nunito','sans-serif';
  }
  @media screen and (min-width: 43.75rem) {
    .heading {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
  }
  .paragraph, .paragraphNoMargin, .paragraphGroup > p {
    font-family: 'Nunito', sans-serif;
    font-size: 1.25rem;
    text-align: justify;
  }
  .paragraphNoMargin {
    margin: 0;
  }
  .paragraphGroup > p:last-child {
    margin: 0;
  }
  .about .meetteam{
    text-align: center;
    font-weight: bold;
  }
  .applybtn{
    
  }
  
.cardview{
    width: 280px;
    height: auto;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    /*border-radius: 15px;
    transition: .2s;
    box-shadow: 2px 1px 18px -11px rgba(0,0,0,0.75);
    -webkit-box-shadow: 2px 1px 18px -11px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 1px 18px -11px rgba(0,0,0,0.75);*/

    /* Added Some Border Radius & Transitions */
    transition: box-shadow 0.7s ease;
    border-radius: 12px;
}


.cardview:hover{
    box-shadow: 2px 1px 18px -8px rgba(0,0,0,0.75);
    -webkit-box-shadow: 2px 1px 18px -8px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 1px 18px -8px rgba(0,0,0,0.75);
}

.cardview  .profile{
    width: 175px;
    height: 175px;
    border-radius: 50%;
    margin-bottom: 30px;
    background-position: center;
    background-size: cover;
}

.cardview h4{
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
}


.role{
    font-family: 'Raleway', sans-serif !important;
    font-weight: normal !important;
}

.linkedin{
    color:rgb(0, 119, 181);
    transition: all 0.5s ease;
}

.linkedin:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.cardview small{
    text-align: center;
}

.sub-role{
    font-weight: 900;
    color: blue;
}

.socialprofile{
    margin: 5px;
}

.twitter{
    color:rgb(29, 161, 242);
}

.twitter:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: all 0.5s ease;
}

.cardview p {
    margin-top: 10px;
}

@media screen and (max-width:750px){
    .cardview{
        max-width: 350px;
        width: 80%;
    }

}
.team > div{
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

/* Does that do anything? */
/* .team div CardView{
    margin: 10px;
} */ 

.team hr{
    margin:15px 5%;
}

/* Class for proper positioning of divs with 2 Team Members - they seemed far apart before. */
div.two-membered{
    width: 78%;
    display: flex;
    justify-content: space-around;
}

@media screen and (max-width:750px) {
    .team div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    div.two-membered{
        width: 100%;
    }
}

.member .volunteer .contributeRoleToggle {
  display: block;
  margin: 2rem auto 1.2rem;
  color: #fff;
  font-family: "Nunito", "sans-serif";
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  transition: 0.2s;
  transition-timing-function: ease-in-out;
  padding: 1rem;
  background-color: #4d5eff;
  border-radius: 50px;
  width: 95%;
}

.volunteer{
  margin-bottom: 4rem;
}
.member .volunteer .contributeRoleToggle:hover {
  background-color: #5767fe;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); */
}
.member .volunteer .contributeRoleToggle h2 {
  margin: 0;
}
.member .volunteer .contributeRole {
  margin-top: 1.5rem;
}
.member .volunteer .contributeRole h2 {
  font-size: 1.75rem;
  font-weight: bold;
  width: auto;
}

.events{
    margin-top: 7rem!important;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.events h1{
    font-weight: bold;
}
.events > span{
    font-size: 2rem;
}
.events > p{
    text-align: center;
    font-size: 1.3rem;
}
.events .eventcards{
    margin: auto 30px;
} 

@media screen and (min-width:700px){
    .wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        gap: 10px;
    }
    
    /* If only one Event is upcoming, bring it to the center */
    .wrapper:has(a:only-child){
        display: block;
    }
}
@media screen and (max-width:700px){
    .events .eventcards{
        display: flex;
        flex-direction: column;
    }
}
.eventcard{
    cursor: pointer;
    border-radius: 15px;
    overflow: hidden;
    transition: .3s ease-in-out;
    box-shadow: 2px 3px 10px rgba(0,0,0,0.5);
}
.eventcard:hover{
    transition: .3s ease-in-out;
    box-shadow: 2px 3px 20px rgba(0,0,0,0.7);
}
.eventcard img{
    width:100%;
    height: 100%;
    background-position: center;
    background-size: cover;
}
@media screen and (min-width:64rem){
    .eventcard{
        width: 400px;
        height: 240px;
        margin: 20px;
    }
}
@media screen and (max-width:64rem) {
    .eventcard{
        width: 40vw;
        height: 24vw;
        margin: 20px;
    }
}
@media screen and (max-width:700px){
    .eventcard{
        height: 45vw;
        width: 80vw;
        margin: 10px;
    }
}

.eventdetail{
    margin-top: 5.3125rem!important;
}
.eventdetail_details h2,.eventdetail_details h3 {
    font-family: 'Nunito','sans-serif';
    font-weight: bold;
}
.eventdetail_details > h2{
    max-width: 400px;
}
.Description{
    width: 100%;
    font-size: 1.3rem;
    padding : 0 10% 60px 10%;
}
.Description > p{
    text-align: justify;
}
.eventdetail_card{
    overflow: hidden;
    box-shadow: 2px 3px 10px rgba(0,0,0,0.5);
}
.eventdetail_card > img{
    width:100%;
    height: 100%;
    background-position: center;
    background-size: cover;
}
.eventdetail_details_info{
    display: flex;
    align-items: center;
}
.eventdetail_details_info{
    margin-left: 20px;
}
.eventdetail_details_info_text {
    margin-left: 20px;
}
.eventdetail_details_info span{
    font-size: 1.3rem;
}
.button_style{
    margin: 20px;
}
.apply_button{
    color: #fff!important;
    height: 70px!important;
    font-size: 20px!important;
    /* font-weight: bold; */
    border-radius: 50px!important;
    background-color: rgb(59, 186, 125)!important;
}
.button_style Button{
    height: 55px;
    font-size: 1.2rem;
    border-radius: 50px;
    font-weight: 700;
    background-color: rgb(240,240,240);
}
.eventdetail_details h3{
    text-align: center;
    margin-bottom: 1.5rem;
}
.share_buttons{
    display: flex;
    justify-content: space-evenly;
} 

@media screen and (max-width:64rem) {
    .eventdetail_details{
        margin: 50px 5%;
    }
    .eventdetail_details h2{
        margin-left: 20px;
    }
    .eventdetail_card{
        width: 100vw;
        height: auto;
    }
    .button_style Button{
        width: 80vw;
    }
    .carousel{
        width: 80vw;
    }
}
@media screen and (min-width:64rem){
    .eventdetail{
        margin-top: 20vh!important;
        display: flex;
        justify-content: space-evenly;
        margin: 9vh 3%;
    }
    .eventdetail_details h2{
        margin-left: 20px;
    }
    .organizer{
        margin-bottom: 100px;
    }
    .eventdetail_card{
        border-radius: 15px;
        width:50vw;
        height:360px;
    }
    .button_style Button{
        width: 30vw;
        max-width: 400px;
    }
    .carousel{
        width: 854px;
        height: 480px;
        object-fit: cover;
    }
}
@media screen and (min-width:70rem) {
    .eventdetail{
        margin: 9vh 2%;
    }
    .eventdetail_card{
        width:60vw;
        height:420px;
    }
}
@media screen and (min-width:85.5rem) {
    .eventdetail{
        margin: 9vh 3%;
    }
    .eventdetail_card{
        width:60vw;
        height:500px;
    }
}
/* @media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
} */
.login{
	background-image: url('https://bccompsci.club/_next/static/media/join.66de69ac578eace138ae38c72d877bac.jpg');
	background-position: center;
	background-size: cover;
	background-repeat: none;
}
.endsection , .loginWindow > h2{
    font-family: 'Nunito','sans-serif';
    font-weight: bold;
}
.endsection > a:hover{
	text-decoration: none;
}
.loginWindow > h2{
    font-size: 2.5rem;
}
.login{
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	/* background-image: linear-gradient(to right ,red , blue); */
} 
.Modal_Header{ 
	text-align: center ;
	font-family: 'Nunito' ;
	font-weight: bold ;
	margin : 100px 0;
	color: black !important;
	width: 100% !important;
	height: 100%;
}
.Modal_Close_Btn{  
	position: absolute !important;
	right: 0;
	top: 0;
	background-color: transparent;
	border: none;
}
.Modal_Close_Icon{
	font-size: 40px !important;
	font-weight: bold !important;
}
.Modal_Join_Btn{
	align-content: center;
}
.loginWindow{
	width: 400px;
	height: 450px;
	border-radius: 20px;
	padding-top: 50px;
	background-color: #fff;
	box-shadow: 2px 1px 18px -11px rgba(0,0,0,0.75);
    -webkit-box-shadow: 2px 1px 18px -11px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 1px 18px -11px rgba(0,0,0,0.75);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
} 
.joinNow{
  color: #fff !important;
  font-weight: bold;
  padding: .6rem 1.2rem;
  background-color: #4d5eff;
  border-radius: 50px;
  cursor:pointer;
}
.login-provider-button{
	display: flex;
	align-items: center;
	justify-content: center;
	width: auto;
	height: 3em;
	padding-right: 15px;
	border: 1px solid #995b5b;
	border-radius: 5px;
	margin-bottom: 1em;
	background-color: #ffffff;
	color: #222222;
	cursor: pointer;
	transition: -webkit-transform 0.5s ease-in-out;
	transition: transform 0.5s ease-in-out;
	transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}
.login-provider-button:hover{
	-webkit-transform: scale(1.05);
	        transform: scale(1.05);
	background-color: #4285F4;
	border-color: #4285F4;
	color: #FFFFFF;
}
.login-provider-button > img{
	position: relative;
	left: -5.5px;
	width: 2.8em;
	height: 2.8em;
	background-color: #FFF;
} 
.reportIssue{
	padding: 0 100px;
}
@media screen and (max-width:500px) {
	.loginWindow{
		width: 90vw;
	}
	.login-provider-button{
		width: 100%;
	}
	.reportIssue{
		padding:0 50px;
	}
}
.dashboard{
    margin: 15vh 10vw;
}
.dashbaordHeader{
    font-size: 2.25rem;
    margin-top: 120px;
    font-weight: bold;
    font-family: 'Nunito','sans-serif';
    text-align: center;
    margin-bottom: 1rem;
}
.profileborder{
    width: 125px;
    height: 125px;
    border-radius: 50%;
    border:8px solid #4285F4;
    overflow: hidden;
}
.profilePhoto{
    width: 100%;
    height: 100%;
}
.dashboard h1{
    font-weight: bold;
}
.announcements{
    border-radius: 10px;
    box-shadow: -1px -1px 5px 2px rgba(0,0,0,.3);
    padding: 20px;
    margin-top: 40px;
}
.announcementsdiv{
    display: flex;
    flex-direction: column;
    height: auto;
    overflow: hidden;
}
.showmore{
    height: auto;
    overflow: visible !important;
}
.announcements>text{
    border-bottom:1px solid lightgray;
    margin: 5px 0;
}
.announcements>text:hover{
    cursor: pointer;
}
.event_headline{
    font-size: 1.5rem !important;
    color: #007bff;   
    font-weight: bold;
}
.event_description{
    font-size: 1.1rem !important;
}
.event_headline:hover{
    text-decoration: underline;  
    color: #2368b1;  
}
.hr{
    margin: 10px 0;
    border:0;
    border-top: 2px dotted rgba(0,0,0,.3) !important;
}
.announcementsdiv > text > strong{
    font-size: 1.1rem;
}
.announcementsdiv > text > p{
    font-size: 1.3rem;
}
.up_next{
    border-radius: 10px;
    box-shadow: -1px -1px 5px 2px rgba(0,0,0,.3);
    width: 400px;
    margin-top: 40px;
    padding-top: 25px;
    padding-bottom: 20px;
    height: 400px;
}
.up_next div{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.see_more{
    font-size: 1.3rem;
    cursor: pointer;
    color: #007bff;
}
.up_next .see_more{
    padding-left: 20px;
}

@media screen and (max-width:64rem) {
    .up_next{
        width: 80vw;
    }
     .headline{
        width: 100%;
        text-align: center;
        font-size: 1.5rem;
        margin-bottom: 5px;
        font-weight: 600;
    }
}
@media screen and (min-width:64rem) {
    .dashboard_content{
        display: flex;
        justify-content: space-evenly;
    }
    .announcements{
        flex:.9 1;
        margin-right: 30px;
    }
    .up_next{
        max-width:500px;
    }
     .headline{
        text-align: center;
        font-size: 2rem;
        margin-bottom: 5px;
        font-weight: 650;
    }
}

.profileHeader{
    font-size: 2.25rem;
    margin-top: 120px;
    font-weight: bold;
    font-family: 'Nunito','sans-serif';
    text-align: center;
    margin-bottom: 1rem;
  }
.label{
  margin-right: 30px;
}
.profiledetails{
  margin: 30px 20vw;
}
.studentdetail{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.savebtn{
    color: #fff!important;
    width: 80vw !important;
    height: 40px!important;
    margin:20px 0 !important;
    font-size: 15px!important;
    font-weight: bold !important;
    border-radius: 50px!important;
    background-color: #4d5eff !important;
}
input{
  width: 300px;
  height: 35px;
}
input:focus {
  outline: 1px solid #42a5f5;
  transition: ease-in-out;
}
@media screen and (max-width:800px){
  .studentdetail{
    flex-direction: column;
  } 
}
@media screen and (max-width:400px){
  .profiledetails{
    margin: auto;
  }
}

.careerHeader{
    font-size: 2.25rem;
    margin-top: 120px;
    font-weight: bold;
    font-family: 'Nunito','sans-serif';
    text-align: center;
    margin-bottom: 1rem;
  }
.CareerComponent{
    margin:50px;
    font-family: 'Ralway',sans-serif !important;
    display: inline-flex;
    flex-wrap: wrap;
}
.jobcard{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
    height: 200px;
    padding: 15px;
    cursor: pointer;
    border-radius: 15px;
    overflow: hidden;
    transition: .3s ease-in-out;
    box-shadow: 2px 3px 10px rgba(0,0,0,0.5);
    margin: 10px;
}
.jobheader{
    display:flex;
}
.companyLogoborder{
    width:60px !important;
    height: 60px !important;
    border-radius: 50% !important;
    overflow: hidden;
}
.companyLogo{
    width: 100%;
    height: 100%;
} 
.jobHeadline , .companyName{
    font-weight: 500 !important;
}
.loc{
    font-size: 16px !important;
}
.applybtn{
    color: #fff!important;
    height: 40px!important;
    font-size: 15px!important;
    border-radius: 50px!important;
    background-color: #4d5eff !important;
}
@media screen and (max-width:500px) {
    .CareerComponent{
        margin: 100px 10vw;
    }
    .jobcard{
        width: 80vw;
        height: auto;
    }

    .companyLogoborder{
        width:45px !important;
        height: 45px !important;
        min-width: 45px !important;
        max-width: 45px !important;
    }
    
}

