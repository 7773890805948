.ending {
  background-color: #4d5eff;
}
.ending .endingContainer {
  display: flex;
  flex-direction: column;
  margin: 4rem auto 0;
  padding: 5rem 6.25vw;
}
.ending .endingContainer h2 {
  margin: 0;
  text-align: center;
  color: #fff;
  font-size: 1.5rem;
  line-height: 1.25;
  font-weight: bold;
  font-family: 'Nunito', sans-serif;
}
.ending .endingContainer a {
  display: block;
  margin: 3rem auto 0;
  text-align: center;
  text-decoration: none;
  font-family: 'Nunito', sans-serif;
  font-size: 1.25rem;
  transition: 0.2s;
  transition-timing-function: ease-in-out;
  padding: 1rem;
  width: 100%;
  color: #4d5eff;
  font-weight: bold;
  background-color: #fff;
  border-radius: 50px;
}
.ending .endingContainer a:hover {
  background-color: #dae0fe;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}
.ending .endingContainer a:active {
  background-color: #ebefff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
@media screen and (min-width: 43.75rem) {
  .ending .endingContainer {
    margin: 0 auto;
    max-width: 76.875rem;
  }
  .ending .endingContainer h2 {
    font-size: 2.2rem;
    line-height: initial;
  }
  .ending .endingContainer a {
    font-size: 1.5rem;
    padding: 1rem 2.5rem;
    width: auto;
  }
}
@media screen and (min-width: 64rem) {
  .ending .endingContainer h2 {
    font-size: 2.5rem;
  }
  .ending .endingContainer a {
    font-size: 1.75rem;
  }
}
