.profileHeader{
    font-size: 2.25rem;
    margin-top: 120px;
    font-weight: bold;
    font-family: 'Nunito','sans-serif';
    text-align: center;
    margin-bottom: 1rem;
  }
.label{
  margin-right: 30px;
}
.profiledetails{
  margin: 30px 20vw;
}
.studentdetail{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.savebtn{
    color: #fff!important;
    width: 80vw !important;
    height: 40px!important;
    margin:20px 0 !important;
    font-size: 15px!important;
    font-weight: bold !important;
    border-radius: 50px!important;
    background-color: #4d5eff !important;
}
input{
  width: 300px;
  height: 35px;
}
input:focus {
  outline: 1px solid #42a5f5;
  transition: ease-in-out;
}
@media screen and (max-width:800px){
  .studentdetail{
    flex-direction: column;
  } 
}
@media screen and (max-width:400px){
  .profiledetails{
    margin: auto;
  }
}
