.team > div{
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

/* Does that do anything? */
/* .team div CardView{
    margin: 10px;
} */ 

.team hr{
    margin:15px 5%;
}

/* Class for proper positioning of divs with 2 Team Members - they seemed far apart before. */
div.two-membered{
    width: 78%;
    display: flex;
    justify-content: space-around;
}

@media screen and (max-width:750px) {
    .team div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    div.two-membered{
        width: 100%;
    }
}
